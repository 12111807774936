.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.blur {
  filter: blur(5px);
}

.centered {
  display: flex;
  justify-content: center;
  text-align: center;
}

.container {
  padding-bottom: 8px;
}

.dialog {
  box-shadow: 0 8px 6px -6px black;
  position: static;
  top: 30%;
}

.headerRad {
  background: #141414;
  padding: 16px;
  border-radius: 50px;
  display: flex;
  justify-content: center;
}

.image {
  width: 300px;
}

.instaDialog {
  border: 16px solid black;
  z-index: 30;
  border-radius: 10px;
  padding: 20px;
  background-color: white;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
  max-width: 90%;
}

.nav-link {
  cursor: pointer;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 10;
}

.sectionStyle {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
